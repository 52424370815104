import request from '../utils/request'

// API-F28 - [SubscriptionController] subscription/bundle/create 訂閱套裝
export function addSubscriptionBundle (data) {
  return request({
    url: `/subscription/bundle/create`,
    method: 'post',
    data
  })
}

// API-F33 - [SubscriptionController] subscription/bundle/delete 刪除訂閱捆綁
export function delSubscriptionBundle (data) {
  return request({
    url: `/subscription/bundle/${data.subscription_bundle_id}/delete`,
    method: 'delete'
  })
}

// API-F70 - [SubscriptionController] subscription/trials/{token} 訂閱試用
export function setTrials (token) {
  return request({
    url: `/subscription/trials/${token}`,
    method: 'get'
  })
}
